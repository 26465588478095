import { DisplayTemplateFieldChevronList } from './../data/class';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { SponsorsService } from './../services/sponsors.service';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProgramService } from '../services/program.service';
import { PromotionService } from '../services/promotion.service';
import { SecurityService } from '../services/security/security.service';
import { faChevronUp, faCalendar, faChevronDown, faTrash, faSave, faTimes, faPlus, faSync, faDesktop, faInfoCircle, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { DateHelperService } from '../services/helpers/date-helper.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PromotionPreviewModalComponent } from '../promotion-preview-modal/promotion-preview-modal.component';
import { removeLink, createLink } from '../data/links';

@Component({
  selector: 'app-display-xml-tab',
  templateUrl: './display-xml-tab.component.html',
  styleUrls: ['./display-xml-tab.component.scss'],
  animations: [
    trigger('expandCollapse', [
        state('open', style({
            'height': '*'
        })),
        state('close', style({
            'height': '0px',
            'visibility': 'hidden'
        })),
        transition('open <=> close', animate(200))
    ]),
]
})
export class DisplayXmlTabComponent implements OnInit, OnChanges, OnDestroy {

  @Input() selectedPromSponsor: any;
  @Input() selectedTemplate: any;
  @Input() selectedProgramId: number;
  @Input() sponsorshipTag: string;
  templateId: number;
  model: any = {completionIndicator:{}};
  promoSponsor: any;
  displayTemplateId: number;
  promotionId: number;
  rawJSON: any;
  templateName: string;
  templateDescription: string;
  disableTemplateEdit: boolean;
  parentId: number;
  childCompletionPromoCodes: any[];
  childPromoCodes: any[];
  programPackages: any[];
  progressType: any[];
  periodValues: any[];
  selectedAlternativeModel: any = {};
  selectedPromoCodeModel: any = {};
  editPromoCodeModel: any = [];
  editAlternativeModel: any = [];
  currencyValue = false;
  hideDollarSign = false;
  hideAllActions = true;
  hidePromoAction = false;
  hideDetails = true;
  hideModalDetails = true;
  clone = false;
  faChevronUp = faChevronUp;
  faCalendar = faCalendar;
  faChevronDown = faChevronDown;
  faTrash = faTrash;
  faSave = faSave;
  faTimes = faTimes;
  faPlus = faPlus;
  faSync = faSync;
  faDesktop = faDesktop;
  faInfoCircle = faInfoCircle;
  faAngleUp = faAngleUp;
  showJSON: boolean;
  buttonText: String;
  videoHTML: String
  show = false;
  openFieldChevronList: DisplayTemplateFieldChevronList = new DisplayTemplateFieldChevronList();
  completionIndicatorSelected = false;
  expandedLookup : boolean[] = Array(6).fill(false);
  disableButtonUrl: boolean = false;
  customUrlAttribute: string;

  constructor(
    private route: ActivatedRoute,
    private securityService: SecurityService,
    private programService: ProgramService,
    private promotionService: PromotionService,
    private toastr: ToastrService,
    private sponsorSService: SponsorsService,
    private responseHelperService: ResponseHelperService,
    private router: Router,
    private dateHelperService: DateHelperService,
    private modalHelper: NgbModal,
    private activatedRoute: ActivatedRoute,
  ) { }
  
  ngOnDestroy(): void {
   removeLink();
  }

  ngOnInit(): void {
    createLink();
    this.promotionId = this.route.snapshot.params.promotionId;
    this.templateId = this.route.snapshot.params.templateId;

    if (this.templateId) {
      this.getDisplayTemplate();
    } else if (this.promotionId) {
      this.selectedTemplate = {};
      this.rawJSON = {};
      this.initModel();
    }

    this.init();
    this.selectedProgramId = this.route.snapshot.params.id;
    if (this.selectedProgramId) {
      this.getProgramPackages();
    }
    this.getChildCompletionPromoData();
    this.show = true;
    this.updateCheck();

  }

  init(): void {
    this.promoSponsor = this.selectedPromSponsor;
    this.showJSON = false;

    if(this.promoSponsor !== undefined && this.promoSponsor !== null){
      this.customUrlAttribute = this.promoSponsor.customUrlAttribute;
    }


    this.activatedRoute.queryParams.subscribe(params => {
      if(params.clone){
        this.clone = true;
      }
  });

    if (this.promoSponsor) {

      if (this.promoSponsor.displayXML && this.promoSponsor.displayXML !== '') {
        this.rawJSON = JSON.parse(this.promoSponsor.displayXML);
      } else {
        this.rawJSON = {};
      }

      this.promotionId = this.promoSponsor.promotionId;
      this.displayTemplateId = null;
    }

    this.securityService.disableTemplateEdit.subscribe((resp: any) => {
      this.disableTemplateEdit = resp;
    });

    this.parentId = null;
    this.childCompletionPromoCodes = [];
    this.childPromoCodes = [];

    const singleStep: any = {};
    singleStep.code = 'SingleStep';
    singleStep.text = 'Single Step';

    const multiStep: any = {};
    multiStep.code = 'MultiStep';
    multiStep.text = 'Multi Step';

    const count: any = {};
    count.code = 'COUNT';
    count.text = 'Count';

    this.progressType = [
      singleStep,
      multiStep,
      count
    ];

    const day: any = {};
    day.code = 'DAY';
    day.text = 'DAY';

    const week: any = {};
    week.code = 'WEEK';
    week.text = 'WEEK';

    const month: any = {};
    month.code = 'MONTH';
    month.text = 'MONTH';

    const quarter: any = {};
    quarter.code = 'QUARTER';
    quarter.text = 'QUARTER';

    const year: any = {};
    year.code = 'YEAR';
    year.text = 'YEAR';

    const campaign: any = {};
    campaign.code = 'CAMPAIGN';
    campaign.text = 'CAMPAIGN';

    const planYear: any = {};
    planYear.code = 'PLANYEAR';
    planYear.text = 'PLAN YEAR';

    const custom: any = {};
    custom.code = 'CUSTOM';
    custom.text = 'CUSTOM';

    this.periodValues = [day, week, month, quarter, year, campaign, planYear, custom];
  }

  initModel(): void {
    this.model = {};

    if (this.rawJSON && !this.isObjectEmpty(this.rawJSON)) {
      this.model = this.rawJSON;
      if (this.model.completionIndicator && this.isCompletionIndicatorEmpty(this.model.completionIndicator)) {
        this.setCompletionIndicator();
      } else if(this.model.completionIndicator) {
        this.completionIndicatorSelected = true;
      } else {
        this.completionIndicatorSelected = false;
      }
      if (!this.model.resultsIndicator || !this.model.resultsIndicator.showResults) {
        this.setResultsIndicator();
      } else {
        this.model.resultsIndicator.showResults = true;
      }
      if (!this.model.alternativesConfiguration || !this.model.alternativesConfiguration.displayLink) {
        this.setAlternativesConfiguration();
      } else {
        this.model.alternativesConfiguration.displayLink = true;
      }
      if (this.model.displayNewFlagOn) {
        this.model.displayNewFlagOn = this.dateHelperService.toISOFormat(this.model.displayNewFlagOn)
        this.model.displayNewFlagOn = this.dateHelperService.stringToNgbDate(this.model.displayNewFlagOn);
      }
      if (this.model.displayNewFlagUntil) {
        this.model.displayNewFlagUntil = this.dateHelperService.toISOFormat(this.model.displayNewFlagUntil)
        this.model.displayNewFlagUntil = this.dateHelperService.stringToNgbDate(this.model.displayNewFlagUntil);
      }
      if (this.model.displayExpireFlagOn) {
        this.model.displayExpireFlagOn = this.dateHelperService.toISOFormat(this.model.displayExpireFlagOn)
        this.model.displayExpireFlagOn = this.dateHelperService.stringToNgbDate(this.model.displayExpireFlagOn);
      }
      if (this.model.displayExpireFlagUntil) {
        this.model.displayExpireFlagUntil = this.dateHelperService.toISOFormat(this.model.displayExpireFlagUntil)
        this.model.displayExpireFlagUntil = this.dateHelperService.stringToNgbDate(this.model.displayExpireFlagUntil);
      }
      if (this.model.completionIndicator && this.model.completionIndicator.completionFromDate) {
        this.model.completionIndicator.completionFromDate = this.dateHelperService.toISOFormat(this.model.completionIndicator.completionFromDate);
        this.model.completionIndicator.completionFromDate = this.dateHelperService.stringToNgbDate(this.model.completionIndicator.completionFromDate);
      }
      if (this.model.completionIndicator && this.model.completionIndicator.completionToDate) {
        this.model.completionIndicator.completionToDate = this.dateHelperService.toISOFormat(this.model.completionIndicator.completionToDate);
        this.model.completionIndicator.completionToDate = this.dateHelperService.stringToNgbDate(this.model.completionIndicator.completionToDate);
      }

      if(this.customUrlAttribute !== undefined && this.customUrlAttribute !== null && this.customUrlAttribute !== '') {
        this.disableButtonUrl = true;
      }

    } else {
      this.model.title = '';
      this.model.subtitle = '';
      this.model.imageUrl = '';
      this.model.displayAmount = '';
      this.model.displayCurrency = '';
      this.model.displayRewardImageUrl = '';
      this.model.buttonText = '';
      this.model.buttonUrl = '';
      this.customUrlAttribute = '';
      this.model.videoHTML = '';
      this.model.displayImageUrl = '';
      this.model.displayOrder = 0;
      this.model.displayNewFlagOn = '';
      this.model.displayNewFlagUntil = '';
      this.model.displayExpireFlagOn = '';
      this.model.displayExpireFlagUntil = '';
      this.model.description = '';
      this.model.disclaimer = '';
      this.model.isGatekeeper = false;
      this.setResultsIndicator();
      this.setCompletionIndicator();
      this.setAlternativesConfiguration();
    }
    this.previewHTML();
  }

  private getDisplayTemplate(): void {
    if (this.templateId) {
      this.promotionService.getPromotionDisplayTemplate(this.templateId, {format: "json"}).subscribe((data: any) => {
        if (data.success) {
          this.selectedTemplate = data.entity;
          if (this.selectedTemplate && !this.clone) { // in a promotion template
            if (this.selectedTemplate.id) {
              if (this.selectedTemplate.displayXml !== '') {
                this.rawJSON = JSON.parse(this.selectedTemplate.displayXml);
              } else {
                this.rawJSON = {};
              }
              this.templateName = this.selectedTemplate.name;
              this.templateDescription = this.selectedTemplate.description;
            }
            this.promotionId = this.selectedTemplate.promotionId;
            this.customUrlAttribute = this.selectedTemplate.customUrlAttribute;
          } else if (this.selectedTemplate && this.clone) { // in a promotion template that is being cloned
            if (this.selectedTemplate.id) {
              if (this.selectedTemplate.displayXml !== '') {
                this.rawJSON = JSON.parse(this.selectedTemplate.displayXml);
              } else {
                this.rawJSON = {};
              }
            }
            this.promotionId = this.selectedTemplate.promotionId;
            this.selectedTemplate.name = '';
            this.selectedTemplate.id = null;
            this.selectedTemplate.packageId = null;
            this.selectedTemplate.description = null;
            this.customUrlAttribute = this.selectedTemplate.customUrlAttribute;
          }
          this.initModel();
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedPromSponsor) {
      this.init();
      this.initModel();
      this.getChildCompletionPromoData();
    }
  }

  // TODO:
  isCompletionIndicatorEmpty(completionIndicator: any): boolean {
    if (
      !completionIndicator || Object.keys(completionIndicator).length  === 0 ||
      (completionIndicator.progressType === null &&
      completionIndicator.minCount === null &&
      completionIndicator.maxCount === null &&
      completionIndicator.period === null)
    ) {
      return true;
    }
    return false;
  }

  setCompletionIndicator(): void {
    this.completionIndicatorSelected = true;
    this.model.completionIndicator = {};
    this.model.completionIndicator.progressType = 'SingleStep';
    this.model.completionIndicator.minCount = 1;
    this.model.completionIndicator.maxCount = 1;
    this.model.completionIndicator.period = 'DAY';
    this.model.completionIndicator.useChildren = false;
  }

  setResultsIndicator(): void {
    this.model.resultsIndicator = {};
    this.model.resultsIndicator.showResults = false;
    this.model.resultsIndicator.resultsLabel = '';
    this.model.resultsIndicator.resultsUrl = '';
    this.model.resultsIndicator.resultsIcon = '';
  }

  setAlternativesConfiguration(): void {
    this.model.alternativesConfiguration = {};
    this.model.alternativesConfiguration.displayLink = false;
    this.model.alternativesConfiguration.linkLabel = '';
    this.model.alternativesConfiguration.toolTip = '';
    this.model.alternativesConfiguration.submissionBehaviorCode = '';
  }

  isObjectEmpty(obj: any): boolean {
    if (obj === null || obj === undefined) {
      return true;
    }
    return Object.keys(obj).length === 0;
  }

  processNumber(str: string, base?: number): any {
    const possibleNumber = parseInt(str, base);
    const possibleNumberStr = possibleNumber.toString();
    if (str === possibleNumberStr) {
      return possibleNumber;
    }
    return str;
  }

  processAttributes(child: any): any {
    const attrs = child.attributes;
    const innerObj: any = {};
    for (let i = attrs.length - 1; i >= 0; i--) {
      innerObj[attrs[i].name] = attrs[i].value;
    }
    if (child.textContent.trim() !== '' && $(child).children().length === 0) {
      innerObj.value = this.processNumber(child.textContent.trim(), 10);
    }
    return innerObj;
  }

  getProgramPackages(): void {
    this.programService.getProgramPackages(this.selectedProgramId).subscribe((data: any) => {
      if (data.success) {
        this.programPackages = data.entity;
      }
    });
  }

  getChildCompletionPromoData(): void {
    if (this.promotionId) {
      this.promotionService.getChildCompletionPromoData(this.promotionId).subscribe((promoCodeData: any) => {
        if (promoCodeData.success) {
          this.parentId = promoCodeData.entity.parentId;
          this.childCompletionPromoCodes = promoCodeData.entity.childCompletionPromoCodes;
          this.childPromoCodes = promoCodeData.entity.childPromoCodes;
        }
      });
    }
  }

  handleAlternativeEvent(data: any): void {
    switch (data.type) {
      case 'add':
        this.addAlternative(data.value);
        break;
      case 'save':
        this.saveAlternative(data.index, data.value);
        break;
      default:
        this.removeAlternative(data.index);
        break;
    }
  }

  editAlternative(index: number): void {
    if (this.openFieldChevronList.alternativeIndex === index) {
      this.openFieldChevronList.alternativeIndex = null;
    } else {
      this.openFieldChevronList.alternativeIndex = index;
    }
  }

  addAlternative(selectedAlternativeModel: any): void {
    if (this.model.alternativesConfiguration.alternatives === undefined || this.model.alternativesConfiguration.alternatives === ''
      || this.model.alternativesConfiguration.alternatives === null) {
      this.model.alternativesConfiguration.alternatives = { alternative: [] };
    }
    this.model.alternativesConfiguration.alternatives.alternative.push(selectedAlternativeModel);
    this.toastr.success('Alternative added');
  }

  saveAlternative(i: any, alternative: any): void {
    if (this.model.alternativesConfiguration.alternatives.alternative[i].fieldIndex !== alternative.fieldIndex) {
      this.isFieldIndexUsed(alternative.fieldIndex);
    }
    this.model.alternativesConfiguration.alternatives.alternative[i] = alternative;
    this.toastr.success('Alternative saved');
  }

  removeAlternative(i: any): void {
    this.model.alternativesConfiguration.alternatives.alternative.splice(i, 1);
    this.editAlternativeModel.splice(i, 1);
    this.toastr.success('Alternative removed');
  }

  handlePromoCodeEvent(data: any): void {
    switch (data.type) {
      case 'add':
        this.addPromoCode(data.value);
        break;
      case 'save':
        this.savePromoCode(data.index, data.value);
        break;
      case 'add-child-promos':
        // TODO:
        // this.addChildPromoCodes(data.index, data.value);
        break;
      default:
        this.removePromoCode(data.index);
        break;
    }
  }

  isFieldIndexUsed(idx: number): boolean {
    if (!idx) {
      return false;
    }
    for (const property in this.model) {
      if (this.model.hasOwnProperty(property)) {
        for (const inner in this.model[property]) {
          if (typeof (this.model[property][inner].forEach) === 'function') {
            for (const fieldMapping in this.model[property][inner]) {
              if (true) {
                if (this.model[property][inner][fieldMapping].fieldIndex === idx) {
                  this.toastr.warning('Field index already used');
                  return true;
                }
              }
            }
          } else {
            if (this.model[property][inner].fieldIndex === idx) {
              this.toastr.warning('Field index already used');
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  editPromoCode(index: number): void {
    if (this.openFieldChevronList.promoCodeIndex === index) {
      this.openFieldChevronList.promoCodeIndex = null;
    } else {
      this.openFieldChevronList.promoCodeIndex = index;
    }
  }

  addChildPromoCodes(): void {
    if (this.childCompletionPromoCodes.length > 0) {
      this.childCompletionPromoCodes.forEach((pc: any) => {
        if (pc !== '') {
          this.selectedPromoCodeModel.promotionCode = pc;
          // TODO:
          // this.addPromoCode();
        }
      });
    } else if (this.childPromoCodes.length > 0) {
      this.childPromoCodes.forEach((pc: any) => {
        if (pc !== '') {
          this.selectedPromoCodeModel.promotionCode = pc;
          // TODO:
          // this.addPromoCode();
        }
      });
    }
  }

  addPromoCode(selectedPromoCodeModel: any): void {
    if (this.model.completionIndicator.completionPromoCodes === undefined || this.model.completionIndicator.completionPromoCodes === ''
      || this.model.completionIndicator.completionPromoCodes === null) {
      this.model.completionIndicator.completionPromoCodes = { completionPromoCode: [] };
    }
    this.model.completionIndicator.completionPromoCodes.completionPromoCode.push(selectedPromoCodeModel);
    this.toastr.success('Promo Code added');
  }

  savePromoCode(i: any, promoCode: any): void {
    if (this.model.completionIndicator.completionPromoCodes.completionPromoCode[i].fieldIndex !== promoCode.fieldIndex) {
      this.isFieldIndexUsed(promoCode.fieldIndex);
    }
    this.model.completionIndicator.completionPromoCodes.completionPromoCode[i] = promoCode;
    this.toastr.success('Promo Code saved');
  }

  removePromoCode(i: any): void {
    this.model.completionIndicator.completionPromoCodes.completionPromoCode.splice(i, 1);
    this.editPromoCodeModel.splice(i, 1);
    this.toastr.success('Promo Code removed');
  }

  resizeIbox(event: any, index?: number): void {
        this.expandedLookup[index] = !this.expandedLookup[index];
  }

  clearDate(name: string): void {
    this.model[name] = null;
  }

  cancelDisplayXmlEdit(): void {
    this.router.navigate(['programs/' + this.selectedProgramId + '/promotions']);
  }

  updateCheck(): void {
    if (this.model.displayAmount === '' || this.model.displayAmount === null || this.model.displayAmount === undefined) {
      this.currencyValue = false;
    } else {
      this.currencyValue = true;
    }

    const dollarExp = new RegExp('^dollar', 'i');

    if (dollarExp.test(this.model.displayCurrency)) {
      this.hideDollarSign = false;
    } else {
      this.hideDollarSign = true;
    }

    if (!this.model.buttonText || this.model.buttonText === "") {
      this.hidePromoAction = true;
    } else {
      this.hidePromoAction = false;
    }

    if ((!this.model.videoHTML || this.model.videoHTM === '') && this.hidePromoAction) {
      this.hideAllActions = true;
    } else {
      this.hideAllActions = false;
    }
    this.videoHTML = this.model.videoHTML
    this.buttonText = this.model.buttonText
    $('.promo-image').css('backgroundImage', 'url(' + this.model.imageUrl + ')');
  }

  createXML(): void {
    if (this.model.displayOrder === '' || this.model.displayOrder === null) {
      this.model.displayOrder = 0;
    }

      const completionIndicatorSelected = this.completionIndicatorSelected;
      const resultsIndicatorShowResults = this.model.resultsIndicator.showResults;
      const alternativesConfigurationDisplayLink = this.model.alternativesConfiguration.displayLink;

      const jsonToSend = this.model;
      this.convertDatesToString(jsonToSend);
      this.prepereJsonToSend(jsonToSend);

      const modelJSON = JSON.stringify(jsonToSend, null, '\t');


      if (this.promoSponsor) {
        this.sponsorSService.updateDisplayXml(this.promoSponsor.id, modelJSON, 'json').subscribe((data: any) => {
          if (data.success) {
            this.toastr.success('Configuration saved');
            this.previewXML();
            this.convertDatesToNgbDate(this.model);
            if (!resultsIndicatorShowResults) {
              this.setResultsIndicator();
            }
            if (!alternativesConfigurationDisplayLink) {
              this.setAlternativesConfiguration();
            }
            this.promoSponsor.displayXML = JSON.stringify(this.model, null, '\t'); //this.model; // update main object so tab swaps don't mess things up
            this.promoSponsor.customUrlAttribute = this.customUrlAttribute;
            this.sponsorSService.createOrUpdatePromotionSponsor(this.promoSponsor.id, this.promoSponsor, 'json').subscribe((data: any) => {
            });
          } else {
            this.toastr.error('Error while saving configuration');
          }
        });
      } else if (this.selectedTemplate) {
        this.selectedTemplate.displayXml = modelJSON;
        this.selectedTemplate.customUrlAttribute = this.customUrlAttribute;
        this.selectedTemplate.promotionId = this.promotionId;
        if (this.selectedTemplate.id) { // update the existing template
          // this update has been split into two separate updates to avoid
          // problems with the web app firewall since we were including XML inside of JSON
          let errorWhileSaving = false;
          // const templateCopy = this.selectedTemplate;
          // delete templateCopy.displayXml; // remove the display xml from what we send
          if(!this.selectedTemplate.name || this.selectedTemplate.name === ''){
            this.toastr.error('Promotion Template Name field is empty');
            this.previewXML();
            this.convertDatesToNgbDate(this.model);
            /*if (completionIndicatorSelected) {
              this.setCompletionIndicator();
            }*/
            if (!resultsIndicatorShowResults) {
              this.setResultsIndicator();
            }
            if (!alternativesConfigurationDisplayLink) {
              this.setAlternativesConfiguration();
            }
          }else{
          this.promotionService.updatePromotionDisplayTemplate(this.selectedTemplate.id, this.selectedTemplate, 'json')
            .subscribe((data: any) => {
              this.toastr.success('Configuration saved');
              this.previewXML();
              this.convertDatesToNgbDate(this.model);
              /*if (completionIndicatorSelected) {
                this.setCompletionIndicator();
              }*/
              if (!resultsIndicatorShowResults) {
                this.setResultsIndicator();
              }
              if (!alternativesConfigurationDisplayLink) {
                this.setAlternativesConfiguration();
              }
              this.selectedTemplate = data.entity;
            }, (data: any) => {
              this.responseHelperService.error(this, data.error.error);
              errorWhileSaving = true;
              this.toastr.error(data.error.error);
            });
          this.promotionService.updatePromotionDisplayTemplateXml(this.selectedTemplate.id, modelJSON, 'json')
            .subscribe((data: any) => {
              this.selectedTemplate.displayXml = data.entity;
              // this.ngOnInit();
            }, (data: any) => {
              this.responseHelperService.error(this, data.error.error);
              errorWhileSaving = true;
              this.toastr.error(data.error.error);
            });
          if (!errorWhileSaving) {
            this.toastr.success('Template Saved');
          }
        }
        } else { // insert the new one
          if(!this.selectedTemplate.name || this.selectedTemplate.name === ''){
            this.toastr.error('Promotion Template Name field is empty');
            this.previewXML();
            this.convertDatesToNgbDate(this.model);
            /*if (completionIndicatorSelected) {
              this.setCompletionIndicator();
            }*/
            if (!resultsIndicatorShowResults) {
              this.setResultsIndicator();
            }
            if (!alternativesConfigurationDisplayLink) {
              this.setAlternativesConfiguration();
            }
          }else{
          this.promotionService.createPromotionDisplayTemplate(this.selectedTemplate, 'json').subscribe((data: any) => {
            this.toastr.success('Template Saved');
            this.previewXML();
              this.convertDatesToNgbDate(this.model);
              /*if (completionIndicatorSelected) {
                this.setCompletionIndicator();
              }*/
              if (!resultsIndicatorShowResults) {
                this.setResultsIndicator();
              }
              if (!alternativesConfigurationDisplayLink) {
                this.setAlternativesConfiguration();
              }
              this.selectedTemplate = data.entity;
            this.router.navigate(['programs/' + this.selectedProgramId + '/promotions/' + this.promotionId + '/promotion-display-template/' + data.entity.id], {
              queryParams: { clone: null }
            });
          }, (data: any) => {
            this.responseHelperService.error(this, data.error.error);
            this.toastr.error(data.error.error);
          });
        }
      }
    }
  }
  convertDatesToString(jsonModel: any): void {
    if (jsonModel.displayNewFlagOn) {
      jsonModel.displayNewFlagOn = this.dateHelperService.ngbDateToString(jsonModel.displayNewFlagOn);
    }
    if (jsonModel.displayNewFlagUntil) {
      jsonModel.displayNewFlagUntil = this.dateHelperService.ngbDateToString(jsonModel.displayNewFlagUntil);
    }
    if (jsonModel.displayExpireFlagOn) {
      jsonModel.displayExpireFlagOn = this.dateHelperService.ngbDateToString(jsonModel.displayExpireFlagOn);
    }
    if (jsonModel.displayExpireFlagUntil) {
      jsonModel.displayExpireFlagUntil = this.dateHelperService.ngbDateToString(jsonModel.displayExpireFlagUntil);
    }
    if (jsonModel.completionIndicator && jsonModel.completionIndicator.completionFromDate) {
      jsonModel.completionIndicator.completionFromDate = this.dateHelperService.ngbDateToString(jsonModel.completionIndicator.completionFromDate);
    }
    if (jsonModel.completionIndicator && jsonModel.completionIndicator.completionToDate) {
      jsonModel.completionIndicator.completionToDate = this.dateHelperService.ngbDateToString(jsonModel.completionIndicator.completionToDate);
    }
  }

  convertDatesToNgbDate(jsonModel: any): void {
    if (jsonModel.displayNewFlagOn) {
      jsonModel.displayNewFlagOn = this.dateHelperService.stringDateTimeToNgbDate(jsonModel.displayNewFlagOn);
    }
    if (jsonModel.displayNewFlagUntil) {
      jsonModel.displayNewFlagUntil = this.dateHelperService.stringDateTimeToNgbDate(jsonModel.displayNewFlagUntil);
    }
    if (jsonModel.displayExpireFlagOn) {
      jsonModel.displayExpireFlagOn = this.dateHelperService.stringDateTimeToNgbDate(jsonModel.displayExpireFlagOn);
    }
    if (jsonModel.displayExpireFlagUntil) {
      jsonModel.displayExpireFlagUntil = this.dateHelperService.stringDateTimeToNgbDate(jsonModel.displayExpireFlagUntil);
    }
    if (jsonModel.completionIndicator && jsonModel.completionIndicator.completionFromDate) {
      jsonModel.completionIndicator.completionFromDate = this.dateHelperService.stringDateTimeToNgbDate(jsonModel.completionIndicator.completionFromDate);
    }
    if (jsonModel.completionIndicator && jsonModel.completionIndicator.completionToDate) {
      jsonModel.completionIndicator.completionToDate = this.dateHelperService.stringDateTimeToNgbDate(jsonModel.completionIndicator.completionToDate);
    }
  }

  prepereJsonToSend(jsonModel: any): void {
    if (jsonModel.subtitle === '') {
      delete jsonModel.subtitle;
    }
    if (jsonModel.disclaimer === '') {
      delete jsonModel.disclaimer;
    }
    if (jsonModel.displayCurrency === '') {
      delete jsonModel.displayCurrency;
    }
    if (jsonModel.displayRewardImageUrl === '') {
      delete jsonModel.displayRewardImageUrl;
    }
    if (jsonModel.buttonText === '') {
      delete jsonModel.buttonText;
    }
    if (jsonModel.buttonUrl === '') {
      delete jsonModel.buttonUrl;
    }
    if (jsonModel.videoHTML === '') {
      delete jsonModel.videoHTML;
    }
    if (jsonModel.displayImageUrl === '') {
      delete jsonModel.displayImageUrl;
    }
    if (jsonModel.displayOrder === '') {
      delete jsonModel.displayOrder;
    }
    if (jsonModel.displayNewFlagOn === '') {
      delete jsonModel.displayNewFlagOn;
    }
    if (jsonModel.displayNewFlagUntil === '') {
      delete jsonModel.displayNewFlagUntil;
    }
    if (jsonModel.displayExpireFlagOn === '') {
      delete jsonModel.displayExpireFlagOn;
    }
    if (jsonModel.displayExpireFlagUntil === '') {
      delete jsonModel.displayExpireFlagUntil;
    }
    if (!this.completionIndicatorSelected) {
      delete jsonModel.completionIndicator;
    }
    if (!jsonModel.alternativesConfiguration?.displayLink) {
      jsonModel.alternativesConfiguration = {};
    }
    if (jsonModel.completionIndicator === '') {
      delete jsonModel.completionIndicator;
    }
    if (jsonModel.alternativesConfiguration === '') {
      delete jsonModel.alternativesConfiguration;
    }
    if (jsonModel.capGroup === '') {
      delete jsonModel.capGroup;
    }
    if (jsonModel.thresholdGroup === '') {
      delete jsonModel.thresholdGroup;
    }
    if (jsonModel.thresholdGroupMin === '') {
      delete jsonModel.thresholdGroupMin;
    }
    if (!jsonModel.isGatekeeper) {
      delete jsonModel.isGatekeeper;
    }
    if (jsonModel.healthyRange === '') {
      delete jsonModel.healthyRange;
    }
    if (!jsonModel.resultsIndicator || !jsonModel.resultsIndicator.showResults) {
      jsonModel.resultsIndicator = {};
    }
  }

  updateCompletionIndicator(): void {
    if(this.completionIndicatorSelected) {
      this.setCompletionIndicator();
    } else {
      this.model.completionIndicator = null;
    }
  }

  previewXML(): void {
    const modelJSON = JSON.stringify(this.model, null, '\t');
    this.rawJSON = modelJSON;
    this.showJSON = true;
  }

  israwJSONString(): boolean {
    return this.rawJSON instanceof String;
  }

  previewHTML(): void {
    this.updateCheck();
  }

  // TODO:
  showDesktop(): void {
    this.hideModalFullDetails();
    this.showDetails();
  }


  showDetails(): void {
    const instance = this.modalHelper.open(PromotionPreviewModalComponent);
    instance.componentInstance.model = this.model;
  }

  showFullDetails(): void {
    this.hideDetails = false;
  }

  hideModalFullDetails(): void {
    this.hideModalDetails = true;
  }

  hideFullDetails(): void {
    this.hideDetails = true;
  }

  customUrlAttributeChange(buttonUrlAttribute: any): void {
    if(buttonUrlAttribute !== '') {
      this.model.buttonUrl = '';
      this.disableButtonUrl = true;
    } else {
      this.disableButtonUrl = false;
      this.customUrlAttribute = null;
    }
  }
}
