<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-sm-9">
      <div class="ibox">
        <div class="ibox-title">
          <div class="row">
            <div class="col-md-12">
              <span class="float-right">
                <a
                  class="btn btn-dark-blue btn-sm"
                  routerLink="/integrations/{{
                    selectedIntegrationId
                  }}/manifest"
                  *ngIf="isProgramManager"
                  title="Edit Manifest"
                >
                  <fa-icon [icon]="faExchangeAlt"></fa-icon>
                </a>
              </span>
              <h2>File Loads</h2>
            </div>
          </div>
        </div>
        <div class="ibox-content">
          <div class="row">
            <div class="col-md-12">
              <div class="full-height-scroll" full-scroll>
                <div class="row mb-0 mt-3">
                  <div class="col-md-12">
                    <app-search-filter
                      #searchFiltersChild
                      [searchFilters]="searchFilters"
                    ></app-search-filter>
                    <div class="col-sm-8">
                      <i
                        >Searches all files for this integration and returns the
                        10 most recent results.</i
                      >
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <table class="table table-pointable table-bordered">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Record Status</th>
                        <th>Customer Name</th>
                        <th>Load Date</th>
                        <th>File Name</th>
                        <th *ngIf="isAdmin || integration.openAccess">
                          Uploaded By
                        </th>
                        <th>Messages</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let load of loads"
                        (click)="showLoad($event, load)"
                      >
                        <td>
                          <a
                            routerLink="/integrations/{{
                              selectedIntegrationId
                            }}/loads/{{ load.id }}/"
                            >{{ load.id }}</a
                          >
                        </td>
                        <td>{{ load.status }}</td>
                        <td>{{ load.customerName }}</td>
                        <td>{{ load.loadDate }}</td>
                        <td
                          title="{{ load.fileName }}"
                          class="word-break-break-all"
                        >
                          {{ load.fileName }}
                        </td>
                        <td *ngIf="isAdmin || integration.openAccess">
                          {{ load.uploadedBy }}
                        </td>
                        <td>{{ load.messages[0].message }}</td>
                      </tr>
                      <tr
                        app-is-there-more
                        [numColumns]="numColumns"
                        [newLengthEvent]="newLengthEvent"
                      ></tr>
                    </tbody>
                  </table>
                </div>
                <app-add-more-data-to-list
                  (onScroll)="getLoads(true)"
                  [lock]="lock"
                  [isLoading]="isLoading"
                >
                </app-add-more-data-to-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-3" *ngIf="isShownLoad()">
      <div class="ibox" appScrollWithMe>
        <div class="row integration-details-bar"></div>
        <div class="ibox-content">
          <div id="contact-1" class="tab-pane active">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3 relative-position">
                    <div>
                      <fa-icon
                        class="fa-3x blue-icon-color"
                        [icon]="faFolderOpen"
                      ></fa-icon>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-12 form-group float-right">
                        <span
                          id="status-label"
                          class="badge float-right"
                          [ngClass]="statusHelper[selectedLoad.status]"
                          >{{ selectedLoad.status }}</span
                        >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 float-left">
                        <h2 class="word-break-break-all">
                          {{ selectedLoad.fileName }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div
                  class="form-group float-right"
                  *ngIf="
                    isProgramManager &&
                    allowedAdvanced &&
                    (selectedLoad.status.toUpperCase() === 'STAGED' ||
                      selectedLoad.status.toUpperCase() === 'RETRY')
                  "
                >
                  <button
                    class="btn btn-dark-blue btn-sm float-right"
                    (click)="startImport(selectedLoad.id)"
                    *ngIf="
                      allowedAdvanced &&
                      (selectedLoad.status.toUpperCase() === 'STAGED' ||
                        selectedLoad.status.toUpperCase() === 'RETRY')
                    "
                    title="Import"
                  >
                    <fa-icon [icon]="faFileImport"></fa-icon>
                  </button>
                </div>
                <div
                  class="form-group float-right"
                  *ngIf="
                    isProgramManager &&
                    (selectedLoad.status.toUpperCase() === 'IMPORTING_ERROR' ||
                      selectedLoad.status.toUpperCase() === 'POSTPROCESSED' ||
                      selectedLoad.status.toUpperCase() === 'IMPORTED')
                  "
                >
                  <button
                    class="btn btn-dark-blue btn-sm float-right"
                    (click)="retryImport(selectedLoad.id)"
                    title="Retry"
                    *ngIf="
                      allowedAdvanced &&
                      (selectedLoad.status.toUpperCase() ===
                        'IMPORTING_ERROR' ||
                        selectedLoad.status.toUpperCase() === 'POSTPROCESSED' ||
                        selectedLoad.status.toUpperCase() === 'IMPORTED')
                    "
                  >
                    <fa-icon [icon]="faSyncAlt"></fa-icon>
                  </button>
                </div>
                <div
                  class="form-group float-right"
                  *ngIf="
                    integrationType.toUpperCase() === 'TRANSACTION' &&
                    isProgramManager
                  "
                >
                  <button
                    *ngIf="
                      integrationType.toUpperCase() === 'TRANSACTION' &&
                      allowedAdvanced
                    "
                    class="btn btn-dark-blue btn-sm float-right"
                    [disabled]="
                      selectedLoad.status.toUpperCase() !== 'POSTPROCESSED' || isDisabledProcess
                    "
                    (click)="startProcessing(selectedLoad.id)"
                    title="Process"
                  >
                    <fa-icon [icon]="faCog"></fa-icon>
                  </button>
                </div>
                <div class="form-group float-right">
                  <a
                    class="btn btn-dark-blue btn-sm float-right"
                    routerLink="/integrations/{{
                      selectedIntegrationId
                    }}/loads/{{ selectedLoad.id }}"
                    title="View Imports"
                  >
                    <fa-icon [icon]="faCopy"></fa-icon>
                  </a>
                </div>
              </div>
            </div>
            <div class="client-detail">
              <strong>Details</strong>
              <ul class="list-group clear-list">
                <li class="list-group-item fist-item">
                  <span class="float-right">
                    {{ selectedLoad.integrationId }}</span
                  >
                  Integration ID
                </li>
                <li class="list-group-item">
                  <span class="float-right">
                    {{ selectedLoad.integrationVersion }}</span
                  >
                  Integration Version
                </li>
                <li class="list-group-item">
                  <span class="float-right"> {{ selectedLoad.loadDate }}</span>
                  Load Date
                </li>
                <li class="list-group-item">
                  <span class="float-right"> {{ selectedLoad.loadRun }}</span>
                  File Type
                </li>
                <li class="list-group-item">
                  <span class="float-right">
                    {{ selectedLoad.customerName }}</span
                  >
                  Customer Name
                </li>
                <li class="list-group-item">
                  <span class="float-right"> {{ selectedLoad.fileName }}</span>
                  File Name
                </li>
                <li class="list-group-item" *ngIf="isAdmin">
                  <span class="float-right">
                    {{ selectedLoad.uploadedBy }}
                  </span>
                  Uploaded By
                </li>
                <li class="list-group-item">
                  <span class="float-right">
                    {{ selectedLoad.recordCount }}</span
                  >
                  Record Count
                </li>
                <li class="list-group-item">
                  <span class="float-right">
                    {{ selectedLoad.createdOn | date: "short" }}</span
                  >
                  Created On
                </li>
                <li class="list-group-item">
                  <span class="float-right">
                    {{ selectedLoad.stagingFinishTime | date: "short" }}</span
                  >
                  Staging Finish Time
                </li>
                <li class="list-group-item">
                  <span class="float-right">
                    {{ selectedLoad.importingFinishTime | date: "short" }}</span
                  >
                  Importing Finish Time
                </li>
              </ul>
            </div>
          </div>
          <div class="client-detail" *ngIf="processingLogs?.length">
            <strong>Processing logs</strong>
            <div class="row">
              <div class="col-md-12">
                <table
                  class="table table-pointable table-bordered infinite-table"
                >
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Finished on</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let log of processingLogs">
                      <td>
                        <a [routerLink]="'/processing-log/log'">{{ log.id }}</a>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row integration-details-bar"></div>
    </div>
  </div>
</div>
