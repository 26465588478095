<form [formGroup]="commContentForm" novalidate>
    <div class="modal-header">
      <h1>{{title}}</h1>
    </div>
    <div class="modal-body">
      <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
        {{errorMsg}}
        <a (click)="clearErrorMessage()">
          <span class="float-right close-button">
            <fa-icon [icon]="faTimes"></fa-icon>
          </span>
        </a>
      </div>
      <div class="form-group">
        <label>Language</label>
        <ng-select formControlName="languageId" [items]="languages" bindLabel="languageName" bindValue="id" name="languageId" [clearable]="false"></ng-select>
        <div class="m-t-xs" [hidden]="commContentForm.controls['languageId'].valid">
          <small class="text-danger">Please select language</small>
        </div>
      </div>
      <div class="form-group">
        <label>Subject</label>
        <input formControlName="subject" type="text" class="form-control">
        <div class="m-t-xs" [hidden]="commContentForm.controls['subject'].valid">
          <small class="text-danger">Please input subject</small>
        </div>
      </div>
    <div class="form-group">
        <label>Template</label>
      <div class="form-group" *ngIf="endpointType === 'EMAIL'">
        <label class="control-label">View:</label>
        <span class="m-l-md">
          <div class="radio radio-primary radio-inline">
            <input formControlName="plainTextView" type="radio" checked="true" value="rawDataView" />
            <label>Raw Data</label>
          </div>
        </span>
      </div>
        <div *ngIf="endpointType !== null &amp;&amp; (endpointType !== 'EMAIL' || commContentForm.controls['plainTextView'].value === 'rawDataView')">
          <textarea class="vertical-expand form-control" formControlName="template"></textarea>
        </div>
    </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-dark-blue" type="submit" [disabled]="!commContentForm.valid" (click)="ok()" title="Save">
          <fa-icon [icon]="faSave"></fa-icon>
        </button>
        <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
          <fa-icon [icon]="faTimes"></fa-icon>
        </button>
    </div>
</form>
